@import "../config";
@import "../mixins/typography";
@import "../mixins/box-shadow";

.container {
  flex: 1;
  background-color: map-get($map: $colors, $key: foreground);
  margin-top: map-get($map: $spacing, $key: sitePadding) * 2;
}

.header {
  @include type($size: medium, $color: copyDark, $font: copyBold);
  text-align: center;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;

  .image {
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    border-radius: map-get($map: $spacing, $key: borderRadius);
    background-color: map-get($map: $colors, $key: background);

    img {
      width: 60px;
      min-width: 60px;
      height: 60px;
      min-height: 60px;
      border-radius: map-get($map: $spacing, $key: borderRadius);
    }

    &-contain {
      object-fit: contain;
    }

    &-cover {
      object-fit: cover;
    }
  }
}

.left-space {
  margin-left: map-get($map: $spacing, $key: sitePadding);
  max-width: 80%;
}

.text-light {
  @include type($size: base, $color: copyLight, $font: copy);
}


.text-light-small {
  @include type($size: small, $color: copyLight, $font: copy);
}


.text-bold {
  @include type($size: base, $color: copyDark, $font: copyBold);
}

.text-bold-small {
  @include type($size: small, $color: copyDark, $font: copyBold);
}

.text {
  @include type($size: base, $color: copyDark, $font: copy);
}

.copy {
  @include copy(small);
}

.center-text {
  text-align: center;
}

.text-small {
  @include type($size: small, $color: copyDark, $font: copy);
}

.text-label {
  @include type($size: base, $color: copyDark, $font: copyBold);
}

.text-toggle {
  cursor: pointer;
  @include type($size: small, $color: primary, $font: copy);
  margin-left: 0.25rem;
}

.lines1 {
  // only allow one line of text
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.stars-position {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: map-get($map: $spacing, $key: sitePadding);
}

.stars {
  width: 18rem;

  svg {
    height: 44px;
    width: 44px
  }
}

.label-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: map-get($map: $spacing, $key: sitePadding);
}

.buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @include breakpoint(small, max) {
    flex-direction: column;
  }
}

.button {
  width: 11rem;

  @include breakpoint(small, max) {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}

.review-container {
  textarea {
    height: 10rem;
  }
}

.add-tags {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: map-get($map: $spacing, $key: sitePadding);
  cursor: pointer;
  width: fit-content;
}

.search-results-container {
  margin-top: calc(map-get($map: $spacing, $key: sitePadding) / 2);
  padding-top: calc(map-get($map: $spacing, $key: sitePadding) / 2);
  padding-bottom: calc(map-get($map: $spacing, $key: sitePadding) / 2);
  @include box-shadow();
  border-radius: map-get($map: $spacing, $key: borderRadius);
  background-color: map-get($map: $colors, $key: foreground);
  border: 1px solid map-get($map: $colors, $key: borderLight);
}

.tag-search-result {
  padding: calc(map-get($map: $spacing, $key: sitePadding) / 2);
  padding-left: map-get($map: $spacing, $key: sitePadding);
  cursor: pointer;

  &:hover {
    background-color: map-get($map: $colors, $key: borderLight);
  }
}