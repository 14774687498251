@import '../../config';
@import '../../mixins/typography';
@import '../../mixins/box-shadow';

.container {
  width: 100%;
  background-color: map-get($map: $colors, $key: foreground);
  border-radius: map-get($map: $spacing, $key: borderRadius);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.top-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.user-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .user {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.stars {
  width: 3rem;
}

.thumb {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  cursor: pointer;

  svg {
    width: 1.25rem;
    height: auto;
  }
}

.avatar {
  cursor: pointer;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: map-get($map: $colors, $key: background);

  img {
    width: 48px;
    height: 48px;
    border-radius: map-get($map: $spacing, $key: borderRadius);
    background-color: map-get($map: $colors, $key: background);
  }
}

.spacer {
  height: map-get($map: $spacing, $key: sitePadding);
}

.date-text {
  @include type($size: small, $color: copyLight);
}

.review-text {
  @include copy();
}

.click-text {
  @include type($size: base, $color: primary);
  padding-top: map-get($map: $spacing, $key: sitePadding) / 4;
  cursor: pointer;
}

.user-details {
  margin-left: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .name {
    @include type($size: base, $color: copyDark, $font: copyBold);
    cursor: pointer;
  }

  .subtext {
    @include type($size: small, $color: copyLight, $font: copy);
  }
}

.like-count {
  margin-left: 0.25rem;
}

.star-rating {
  svg {
    width: 24px;
    height: 24px;
  }

  @include breakpoint(small, max) {
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.author {
  @include type($size: tiny, $color: copyDark, $font: copy);
}

.dots-menu {
  cursor: pointer;

  &:hover {
    svg {
      path {
        fill: map-get($map: $colors, $key: copyDark);
      }
    }
  }
}

.edit-modal {
  .title {
    text-align: center;
    @include type($size: large, $color: copyDark, $font: copyBold);
    margin-bottom: map-get($map: $spacing, $key: sitePadding);
  }

  .icon {
    height: 20px;
    width: 20px;
  }
}
