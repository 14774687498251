@import "../config";

.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
    flex-wrap: wrap;
}

.pill {
    margin: calc(map-get($map: $spacing, $key: sitePadding) / 2);
    min-width: 100px;
    cursor: pointer;
}